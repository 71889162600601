import React from 'react';
import { RouteComponentProps } from '@reach/router';

const Details = (props: RouteComponentProps) => {
  return (
    <div>
      Details
    </div>
  );
}

export default Details;
